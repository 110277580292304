import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_QRCodeVue3 = _resolveComponent("QRCodeVue3")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "url-share" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.lang.t("share")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.helpCircleOutline,
                color: "primary"
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.lang.t("info")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.link
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, { style: {"white-space":"normal"} }, {
                default: _withCtx(() => [
                  _createElementVNode("b", null, _toDisplayString(_ctx.url), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyURL())),
                    color: _ctx.copied ? 'success' : ''
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.copied ? _ctx.checkmark : _ctx.copyOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["color"]),
                  (_ctx.sharing)
                    ? (_openBlock(), _createBlock(_component_ion_spinner, {
                        key: 0,
                        color: "success"
                      }))
                    : (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.shareURL()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.shareSocial }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.qrCode
              }, null, 8, ["icon"]),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createVNode(_component_QRCodeVue3, {
                    value: _ctx.url,
                    width: 200,
                    height: 200
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}