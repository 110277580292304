
import { defineComponent, onMounted, ref } from "vue";

import {
  shareSocial,
  link,
  copyOutline,
  helpCircleOutline,
  qrCode,
  checkmark,
} from "ionicons/icons";

import QRCodeVue3 from "qrcode-vue3";

import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonLabel,
  IonIcon,
  IonItem,
  IonButtons,
  IonSpinner,
  IonButton,
  toastController,
} from "@ionic/vue";
import LanguageService from "../../lib/service/LanguageService";
import URLService from "../../lib/service/URLService";
import ShareService from "../../lib/service/ShareService";

export default defineComponent({
  name: "URLShare",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonItem,
    IonButtons,
    IonLabel,
    IonIcon,
    IonButton,
    IonSpinner,
    QRCodeVue3,
  },
  emits: ["ready"],
  props: {
    event: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const lang = LanguageService.getTranslator("main/share");
    const copied = ref(false);
    const sharing = ref(false);
    const url = URLService.getEventURL(props.event);

    const copyURL = async () => {
      await ShareService.copyUrl(url);
      const toast = await toastController.create({
        message: lang.t("urlCopied"),
        duration: 2500,
      });
      await toast.present();
      copied.value = true;
      setTimeout(() => {
        copied.value = false;
      }, 2500);
    };

    const shareURL = async () => {
      sharing.value = true;
      await ShareService.shareUrl(url, lang.t("share"));
      sharing.value = false;
    };

    onMounted(() => {
      emit("ready");
    });

    return {
      lang,
      copied,
      sharing,
      shareSocial,
      link,
      copyOutline,
      qrCode,
      checkmark,
      helpCircleOutline,
      url,
      copyURL,
      shareURL
    };
  }
});
