import {Capacitor} from "@capacitor/core";

class URLService {
    getEventURL(eventId: string): string {
        if(location.hostname === 'localhost' && Capacitor.getPlatform() === 'web') {
            return `http://localhost:8200/a#${eventId}`;
        } else {
            return `https://appointa.net/a#${eventId}`;
        }
    }
}

export default new URLService;