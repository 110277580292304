import { Clipboard } from '@capacitor/clipboard';
import { Capacitor, CapacitorException } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { alertController } from '@ionic/core';
import LanguageService from './LanguageService';

class ShareService {
    canShare: boolean = navigator.share !== undefined || Capacitor.getPlatform() !== 'web';

    async shareUrl(url: string, title: string) {
        try {
        await Share.share({
            title,
            url
        });
    }catch(ex) {
        if(!(ex instanceof CapacitorException) || ex.code === 'UNAVAILABLE') {
            const lang = LanguageService.getTranslator('main/share');
            lang.add(LanguageService.getTranslator('generic'));
            alertController.create({
                header: lang.t('error'),
                message: lang.t('cannotShare'),
                buttons: ['OK']
            }).then(alert => alert.present());
        }
    }
    }

    async copyUrl(url: string) {
        await Clipboard.write({
            url
        });
    }
}

export default new ShareService;